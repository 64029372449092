.search-bar {
  padding: 15px;
}

input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  font-size: 1rem;
}

.index h2,
.index h3 {
  text-align: center;
}

.emojis {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.emoji {
  background: none;
  border: none;
  font-size: 4em;
  display: inline-block;
  cursor: pointer;
  padding: 1px;
}

.pulse {
  animation: pulse 0.4s;
}

@keyframes pulse
{
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.75);
  }
  100% {
    transform: scale(1);
  }
}
